import React, { useCallback, useEffect, useState } from "react";
import { Row, Col ,Button, Container,Card} from "reactstrap";

import { adminUserPut, requestGet ,adminRequestPost} from "../../apis/request";
import { ReactComponent as Paste } from "../../assets/icon/svgs/light/paste.svg";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Tables/datatables.scss";
import PaginationAPIWrap from "../../components/PaginationAPIWrap";
import MobilePaginationAPIWrap from "../../components/MobilePaginationAPIWrap";
import { useMatchStoreListStore} from "../../store/zustand/state";
import moment from "moment";
//itk 매칭 장바구니 탭

function MatchStoreList({ id, callAPI, resetAPI }) {
  const [memberList, setMemberList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [targetCode, setTargetCode] = useState(undefined);
  const [memo, setMemo] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [pageNumber, setpageNumber] = useState([]);
  const [mobilepageNumber, setMobilepageNumber] = useState([]);
  const [activeNum, setActiveNum] = useState(1);
  const [lastpage, setLastpage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const { list, updateList, updateActiveNum } = useMatchStoreListStore();
  const [reserveModal, setReserveModal] = useState(false);
  const [introducePending, setIntroducePending] = useState(false);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");
  const [reserveId, setReserveId] = useState("");
  const [isBeforeApiCalled  , setBeforeApiCalled] = useState(1);

  const onClose = () => {
    setReserveId("");
    setDate("");
    setHour("");
    setMinute("");
    setReserveModal(false);
    setIntroducePending(false)
  };

  const onOpen = (code) => {
    setReserveId(code);
    setReserveModal(true);
  };

  const calculateAge = (birthday) => {
    const birthYear = parseInt(birthday.substring(0, 4), 10);
    const birthMonth = parseInt(birthday.substring(4, 6), 10) - 1; // Month is zero-based in JavaScript Date
    const birthDay = parseInt(birthday.substring(6, 8), 10);

    const today = new Date();
    const birthDate = new Date(birthYear, birthMonth, birthDay);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    // Adjust if the birth date hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  };

  const introduceReserve = async () => {
    try{
      let hourString=hour??'09';
      if (hourString===""){
        hourString='09'
      }
      let minuteString=minute??'00';
      if (minuteString===""){
        minuteString='00'
      }

      console.log(hour,minute,hourString,minuteString)
      let sendDate=`${date} ${hourString}:${minuteString}:00`

      const response = await requestGet(
        `/api/member/check/${reserveId}`
      );
      let isPending=introducePending
      const calculatedAge = calculateAge(response.data.birthday);
      if (
        window.confirm(
          `만(${calculatedAge}세) ${response.data.name}님을 소개 하시겠습니까?`
        )
      ) {
        try {
          let body = {
            userId: id,
            targetCode: reserveId,
            isPending,
          };
          if (isPending){
            body.sendDate=sendDate
          }
          await adminRequestPost("/api/introduce", body);
          alert("요청 성공 하였습니다.");
          onClose()
          callInfo()
        } catch (error) {
          console.log(error);
          let message='소개를 실패하였습니다.'
          if (error.data&&error.data.message){
            message+=`(${error.data.message})`
          }
          alert(message);
        }
      } 

    }catch(error){
      console.log(error);
      let message='소개를 실패하였습니다.'
      if (error.data&&error.data.message){
        message+=`(${error.data.message})`
      }
      alert(message);
    }
  };

  const handleCopyToClipboard = async (text) => {
    if (navigator.clipboard) {
      // 클립보드 API 지원 여부 확인
      try {
        await navigator.clipboard.writeText(text);
        alert("Copied to clipboard");
      } catch (error) {
        console.error("Copy failed", error);
        alert("Copy failed");
      }
    } else {
      fallbackCopyTextToClipboard(text);
    }
  };

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      const msg = successful ? "successful" : "unsuccessful";
      alert("Copied to clipboard");
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      alert("Copy failed");
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  };

  const handleWidthrawalClick = async (e, row, rowIndex) => {
    // 여기에 클릭 시 실행할 로직 추가
    e.stopPropagation();
    if (window.confirm("삭제하시겠습니까?")) {
      try {
        let body = {
          id: row.id,
        };
        await adminUserPut(`/api/introduce/candidate/cancel`,body);
        alert("삭제 성공 하였습니다.");
        callAPI();
        callInfo();
      } catch (error) {
        console.log(error.data.message);
        let message="삭제에 실패 하였습니다."
        if (error?.data?.message){
          message+=`(${error.data.message})`
        }
        alert(message);
      }
    }
  };

  const columns = [
    {
      dataField: "userImg",
      text: "사진",
      headerStyle: { width: '150px' }, // ID 열의 너비를 고정
      style: { width: '150px' }, // 데이터 셀의 너비를 고정
      sort: false,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a
            href={`/member/detail/${row.userId}`}
            style={{ color: "#495057" }}
            target="_blank"
            rel="noreferrer"
          >                
            <div className="image-container">
              <img src={`${row.userImg}`}
                  alt="Thumbnail"
                  className="thumbnail"
                  style={{ width: "100px", height: "100px" }}>
              </img>
            </div>
          </a>
        );
      },
    },
    {
      dataField: "userName",
      text: "이름",
      sort: true,
      // headerStyle: { width: '150px' }, // ID 열의 너비를 고정
      // style: { width: '150px' }, // 데이터 셀의 너비를 고정
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a
            href={`/member/detail/${row.userId}`}
            style={{ color: "#495057" }}
            target="_blank"
            rel="noreferrer"
          >
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "userCode",
      text: "코드",
      sort: true,
      // headerStyle: { width: '200px' }, // ID 열의 너비를 고정
      // style: { width: '200px' }, // 데이터 셀의 너비를 고정
      formatter: (cellContent, row, rowIndex) => {
        return <>{cellContent}<Paste
        width={20}
        onClick={() => handleCopyToClipboard(row.userCode)}
        style={{
          marginLeft: "5px",
          cursor: "pointer",
        }}
      /></>;
      },
    },
    {
      dataField: "userStatus",
      text: "상태",
      sort: true,
      // headerStyle: { width: '150px' }, // ID 열의 너비를 고정
      // style: { width: '150px' }, // 데이터 셀의 너비를 고정
    },
    {
      dataField: "createdAt",
      text: "추가 일자",
      sort: true,
      // headerStyle: { width: '200px' }, // ID 열의 너비를 고정
      // style: { width: '200px' }, // 데이터 셀의 너비를 고정
    },
    {
      dataField: "memo",
      text: "메모",
      sort: true,
      headerStyle: () => {
        return { flexGrow: 1 }; // 남은 공간을 채우도록 설정
      },
      style: { flexGrow: 1 },
    },
    {
      dataField: "sendStatus",
      text: "발송 상태",
      sort: false,
      // headerStyle: { width: '200px' }, // ID 열의 너비를 고정
      // style: { width: '200px' }, // 데이터 셀의 너비를 고정
    },
    {
      dataField: "sendDate",
      text: "발송 일자",
      sort: false,
      // headerStyle: { width: '200px' }, // ID 열의 너비를 고정
      // style: { width: '200px' }, // 데이터 셀의 너비를 고정
    },
    {
      dataField: "action",
      text: "예약",
      // headerStyle: { width: '150px' }, // ID 열의 너비를 고정
      // style: { width: '150px' }, // 데이터 셀의 너비를 고정
      formatter: (cellContent, row, rowIndex) => {
        if (row.sendStatus===""){
          return (
            <button
              onClick={(e) => onOpen(row.userCode)}
              className="btn btn-primary"
            >
              예약
            </button>
          );
        }else{
          return ""
        }

      },
    },
    {
      dataField: "action",
      text: "삭제",
      // headerStyle: { width: '150px' }, // ID 열의 너비를 고정
      // style: { width: '150px' }, // 데이터 셀의 너비를 고정
      formatter: (cellContent, row, rowIndex) => {

          return (
            <button
            onClick={(e) => handleWidthrawalClick(e, row, rowIndex)}
            className="btn btn-dark"
          >
            삭제
          </button>
          );
 

      },
    },

  ];

  const addUser = async () => {
    function inputInit(){
      setTargetCode('')
      setDueDate('')
      setMemo('')
    }

    try {
      let inputCheck=true;
      let inputErrorMessage=''
      let tergetCodeText=''
      let memoText=undefined
      if (targetCode){
        tergetCodeText=targetCode.trim()
        if (tergetCodeText.length ===0){
          inputCheck=false
          inputErrorMessage='타겟코드가 빈문자열입니다.'
        }
      }else{
        inputCheck=false
        inputErrorMessage='타겟코드가 빈문자열입니다.'
      }
      if (memo){
        memoText=memo.trim()
        if (memoText.length ===0){
          memoText=undefined
        }
  
      }
      if (inputCheck){
        let body = { userId: id, targetCode:tergetCodeText, memo:memoText};
        await adminRequestPost("/api/introduce/candidate", body);
        alert("추가 성공 하였습니다.");
        callAPI();
        callInfo();
        inputInit()
      }else{
        alert(inputErrorMessage); 
      }


    } catch (error) {
      console.log(error.data.message);
      let message="추가 실패 하였습니다."
      if (error?.data?.message){
        message+=`(${error.data.message})`
      }
      alert(message);

    }
  };


  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  };

  const callInfo = useCallback(async () => {
    const params = new URLSearchParams();
    if (list) {
      for (var key in list) {
        if (list[key]) {
          params.append(key, list[key]);
        }
      }
    }
    const memberResponse = await requestGet(`/api/introduce/candidate/list/user/${id}?${params}`);
    setMemberList(memberResponse?.data);
    setLastpage(memberResponse?.totalPage);
    setTotalCount(memberResponse?.totalCount);
  }, [list]);

  useEffect(() => {
    setActiveNum(list.page);
    if (id && resetAPI) {
      callInfo();
    }
  }, [callInfo, id, resetAPI, list]);

  useEffect(() => {
    if (memberList?.length > 0) {
      let arr = [];
      memberList.forEach((item) =>{

        let userStatus='알수없음';

        switch (item.userStatus) {
          case 'active':
            userStatus='정상'
            break;
          case 'sleep':
            userStatus='휴면'       
            break;
          case 'sleep2':
            userStatus='기간제차감휴면'       
            break;
          case 'matched':
          case 'matched2':
            userStatus='교제중'       
            break;

          default:
            break;
        }

        arr.push({
          id: item.id,
          userId: item.userId,
          userName: item.userName,
          userImg: item.userImg,
          userCode: item.userCode,
          createdAt: item.createdAt,
          memo: item.memo,
          introduceeGrade: item.introduceeGrade,
          userStatus,
          sendDate:item.sendDate,
          sendStatus:item.sendStatus
        })

      });
      setProductData(arr);
    } else {
      setProductData([]);
    }
  }, [memberList]);

  return (
    <React.Fragment>
      <h3 className="MainText pt-3">매칭 장바구니 목록</h3>
      <p className="card-title-desc"></p>
      <ToolkitProvider
          keyField="id"
          columns={columns}
          data={productData}
          search
      >
        {(toolkitProps) => (
            <React.Fragment>
              <Row className="mb-4">
                  <Col lg="12">
                    <Row className="rowBorderBottom w-100 m-0  customSearchInputWrap">
                      <Col lg={12} className="d-flex p-0 flex-column">
                        <Row className="w-100 m-0">
                          <Col
                            lg={2}
                            className="d-flex align-items-center"
                            style={{
                              padding: "0.47rem 0px 0.75rem 0.47rem",
                              backgroundColor: "#ccc",
                              fontWeight: 700,
                              borderTop: "0.3px solid #fff",
                              borderBottom: "0.3px solid #fff",
                            }}
                          >
                            <span>타겟코드</span>
                          </Col>
                          <Col
                            className="m-0 p-0"
                            style={{
                              borderBottom: "1px solid #ced4da",
                            }}
                          >
                            <div className="h-100">
                              <input
                                type="text"
                                className="js-input-mask form-control customSearchInput h-100"
                                name="targetCode"
                                id="targetCodeLabel"
                                onWheel={(e) => e.target.blur()}
                                value={targetCode}
                                onChange={(e) => setTargetCode(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="w-100 m-0">
                          <Col
                            lg={2}
                            className="d-flex align-items-center"
                            style={{
                              padding: "0.47rem 0px 0.75rem 0.47rem",
                              backgroundColor: "#ccc",
                              fontWeight: 700,
                              borderTop: "0.3px solid #fff",
                              borderBottom: "0.3px solid #fff",
                            }}
                          >
                            <span>메모</span>
                          </Col>
                          <Col
                            className="m-0 p-0"
                            style={{
                              borderBottom: "1px solid #ced4da",
                            }}
                          >
                            <div className="h-100">
                              <input
                                type="text"
                                className="js-input-mask form-control customSearchInput h-100"
                                name="memo"
                                id="memoLabel"
                                value={memo}
                                onChange={(e) => setMemo(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={12} className="d-flex p-0 flex-column">
                        <div>
                          <Button
                            className="w-100"
                            color="primary"
                            style={{ borderRadius: "0px" }}
                            onClick={() => addUser()}
                          >
                            추가하기
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              <Row>
                <Col xl="12">
                  <div className="text-end customTotalcount">
                    검색결과 (
                    {String(totalCount).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    개의 검색결과가 있습니다)
                  </div>
                  <div className="table-responsive">
                    <BootstrapTable
                        keyField={"id"}
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        // selectRow={selectRow}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                    />
                  </div>
                </Col>
              </Row>
              {/* <div ref={btnref} style={{ height: "auto" }}> */}
              {!lastpage ? null : (
                  <Row className="w-100 m-0 p-0 py-3">
                    <Col className="w-100 m-0 p-0 d-none d-lg-flex">
                      <PaginationAPIWrap
                          pageNumber={pageNumber}
                          setpageNumber={setpageNumber}
                          lastpage={lastpage}
                          activeNum={activeNum}
                          setActiveNum={updateActiveNum}
                      />
                    </Col>
                    <Col className="w-100 m-0 p-0 d-flex d-lg-none">
                      <MobilePaginationAPIWrap
                          pageNumber={mobilepageNumber}
                          setpageNumber={setMobilepageNumber}
                          lastpage={lastpage}
                          activeNum={activeNum}
                          setActiveNum={updateActiveNum}
                      />
                    </Col>
                  </Row>
              )}
            </React.Fragment>
        )}
      </ToolkitProvider>
      {reserveModal ? (
<Container fluid className="modalWrap">
  <Row className="h-100 d-flex align-items-center justify-content-center">
    <Col xs="12" sm="10" md="8" lg="6" xl="5">
      <div className="modalOverlay" />
      <Card
        className="modalCard p-4"
        style={{
          position: "relative",
          borderRadius: "15px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2 className="text-center mb-4">소개</h2>

        <Row className="mb-3">
        <Col lg={12} className="d-flex flex-column">
            <div className="d-flex align-items-center mt-2">
              <input
                type="checkbox"
                name="체크박스"
                value={introducePending}
                id="introLabel"
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "5px",
                }}
                onClick={() => setIntroducePending(!introducePending)}
              />
              <label htmlFor={`introLabel`} className="labelText m-0">
                예약 발송
              </label>
            </div>
          </Col>
          {introducePending?<Col>
          <div>
            <div className="input-group">
              <input
                type="date"
                className="form-control customInputLeft"
                name="firstDate"
                id="firstDateLabel"
                min={moment().format("YYYY-MM-DD")}
                max="9999-12-31"
                value={date}
                onChange={(e) =>
                  setDate(moment(e.target.value).format("YYYY-MM-DD"))
                }
                style={{ maxWidth: "9rem", borderRadius: "5px 0 0 5px" }}
              />
              <select
                className="form-select"
                name="firstDateTime"
                style={{ maxWidth: "5rem" }}
                value={hour}
                onChange={(e) => setHour(e.target.value)}
              >
                {Array.from({ length: 14 }, (_, i) => i + 9).map((hour) => (
                  <option key={hour} value={hour.toString().padStart(2, "0")}>
                    {hour.toString().padStart(2, "0")}
                  </option>
                ))}
              </select>
              <select
                className="form-select"
                name="firstDateTime"
                style={{
                  maxWidth: "5rem",
                  borderRadius: "0 5px 5px 0",
                }}
                value={minute}
                onChange={(e) => setMinute(e.target.value)}
              >
                {["00", "10", "20", "30", "40", "50"].map((min) => (
                  <option key={min} value={min}>
                    {min}
                  </option>
                ))}
              </select>
            </div>
          </div>
          </Col>:<></>}


        </Row>
         <Row
         className="w-100 px-0 pt-3 pb-3"
         style={{
           borderTop: "1px solid #ced4da",
           backgroundColor: "#fff",
           position: "sticky",
           bottom: "0",
         }}
       >
         <Col className="text-end">
           <Button
             id="masterAccesBtn"
             color="danger"
             style={{ marginRight: "10px" }}
             onClick={() => onClose()}
           >
             닫기
           </Button>
           <Button
             id="masterAccesBtn"
             color="primary"
             onClick={() => introduceReserve()}
           >
             확인
           </Button>
         </Col>
       </Row>
      
      </Card>
    </Col>
  </Row>
</Container>
      ) : null}
    </React.Fragment>
  );
}

export default MatchStoreList;
